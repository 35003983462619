import { Button, message, Row } from "antd";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useContext, useEffect, useState } from "react";
import { requestSearch } from "../../core/apollo/search/search.service";
// Components
import Carousel from "../../core/components/Carousel/Carousel";
import Informationals from "../../core/components/Informationals/Informationals";
import TowForm from "../../core/components/TowForm/TowForm";
import VehiclesList from "../../core/components/VehiclesList/VehiclesList";
import "./Home.scss";
import Map from "./Map/Map";
import { ArrowLeftOutlined } from "@ant-design/icons";
import StateContext from "../../shared/context/StateContext";
import { ACTIONS } from "../../shared/context/Constants";

const Home = () => {
    const { state: contextState, dispatch } = useContext(StateContext);
    const [vehicles, setVehicles] = useState<any>([]);
    const [state, setState] = useState<string | null>(null);

    const submitForm = (values: any) => {
        setVehicles([]);
        console.log("🚀 ~ values", values);
        requestSearch({
            // createdAt: values.date,
            rangeStart: values.range[0],
            rangeEnd: values.range[1],
            make: values.make.value,
            model: values.model.value,
            vin: values.vin,
            licensePlateNumber: values.sp,
            vehicleYear: values.year
                ? parseInt(dayjs(values.year).format("YYYY"))
                : values.year,
            towLocation: values.towLocation
        })
            .then((resp) => {
                if (isEmpty(resp)) {
                    message.warning(
                        "It looks like no vehicles with the selected details were found."
                    );
                }
                console.log(resp);
                setVehicles(resp);
            })
            .catch((err) => {
                message.error(
                    "Oops! It looks like something went wrong. Please try again later."
                );
                console.log("ERROR", err);
            });
    };

    useEffect(() => {
      if(!contextState?.state) setState(null);
    }, [contextState])

    const handleBackButton = () => {
        setState(null);
        dispatch({ type: ACTIONS.SET_STATE, state: null });
    }    

    const currentView = !state && !contextState?.state ? <Row
        justify="center"
        align="middle"
        style={{ marginBottom: "1rem" }}
    >
        <Map setState={setState} />
    </Row> : <>
        <div>
            <Button type="default" className="back-button" onClick={handleBackButton}>
                <ArrowLeftOutlined /> Back to map
            </Button>
            <Row
                justify="start"
                align="middle"
                style={{ marginBottom: "1rem" }}
            >
                <h1 style={{ flex: "1" }}>
                    My car was towed, now what do I do?
                </h1>
            </Row>
            {state === "UT" ? <Informationals /> : <></>}
            <Row justify="center">
                <h2>Let’s find your vehicle</h2>
            </Row>
            <TowForm state={state} submitForm={submitForm} />
            {!isEmpty(vehicles) && <VehiclesList vehicles={vehicles} />}
        </div>
        {/* <Carousel /> */}
    </>;

    return <div className="home-page">{currentView}</div>;
};

export default Home;

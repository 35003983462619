import { Col } from "antd";

const ListColumn = (props: any) => {
    const { display, label, small } = props;
    return (
        <Col
            xs={24}
            sm={12}
            md={small ? 2 : 4}
            lg={small ? 2 : 4}
            xl={small ? 2 : 4}
        >
            <strong>
                <span className="small-label">{label}</span>
                {display}
            </strong>
        </Col>
    );
};

export default ListColumn;

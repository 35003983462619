import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
} from "@apollo/client";

import { onError } from "@apollo/client/link/error";
import { message } from "antd";
// import apolloLogger from "apollo-link-logger";

const GRAPH_ENDPOINT = `${process.env.REACT_APP_API_URL}/graphql/`;

export const TowProApolloClient = (byPassTokenValidation = false) => {
    const errorLink = onError(({ response }: any) => {
        // if (response && response.errors) {
        //     if (
        //         response.errors[0] &&
        //         response.errors[0].internalCode === 4009 &&
        //         response.errors[0].message.includes("Jwt Expired")
        //     ) {
        //         expiredEffect();
        //     }
        // }
    });

    const responseLink = new ApolloLink((operation, forward) => {
        return forward(operation).map((response: any) => {
            if (response.errors) {
                const errors = response.errors.map((x: any) => x.details);
                const messageString = errors.join(". ");
                if (
                    !messageString.includes("Failed to fetch") &&
                    !messageString.includes("Token not found") &&
                    !messageString.includes("JWT Bearer expired")
                ) {
                    message.error(messageString);
                }
            }

            return response;
        });
    });

    const link = [];
    link.push(responseLink);

    link.push(errorLink);

    // link.push(apolloLogger);

    link.push(
        new HttpLink({
            uri: GRAPH_ENDPOINT,
            // headers: {
            //     Authorization: `Bearer ${token}`,
            // },
        })
    );

    const client = new ApolloClient({
        cache: new InMemoryCache(),
        defaultOptions: {
            query: {
                errorPolicy: "all",
                fetchPolicy: "no-cache",
            },
            mutate: {
                errorPolicy: "all",
                fetchPolicy: "no-cache",
            },
            watchQuery: {
                errorPolicy: "all",
                fetchPolicy: "no-cache",
            },
        },
        link: ApolloLink.from(link),
    });

    return client;
};

import crashIcon from "../../../assets/images/car-crash.png";
import privateTow from "../../../assets/images/no-parking.png";
import policeIcon from "../../../assets/images/tow-truck.png";

export const informationBoxes = [
    {
        selectionType: "crash",
        title: "My car was in a crash",
        icon: crashIcon,
    },
    {
        selectionType: "police",
        title: "My car was impounded by police",
        icon: policeIcon,
    },
    {
        selectionType: "private",
        title: "My car was on private property",
        icon: privateTow,
    },
];

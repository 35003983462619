import { useState } from "react";
import { Button, Col, Row } from "antd";
import "./VehiclesList.scss";
import dayjs from "dayjs";
import { vinMask, formatPhone } from "../../../core/helpers/dataFormats";
import { isEmpty } from "lodash";
import ListColumn from "./ListColumn";

const VehiclesList = (props: any) => {
    const [vehicleInfo, setVehicleInfo] = useState<any>({});

    const { vehicles } = props;

    const getTowCompanyName = (vehicleInfo: any) => {
        const { activeTowOrder } = vehicleInfo;
        return (
            activeTowOrder?.otherTowCompanyData?.name ||
            activeTowOrder.towCompany.name
        );
    };

    const getTowCompanyPhone = (vehicleInfo: any) => {
        const { activeTowOrder } = vehicleInfo;
        return (
            activeTowOrder?.otherTowCompanyData?.phone ||
            activeTowOrder.towCompany.contact.phone
        );
    };

    return (
        <Row justify="center">
            <div className="vehicle-finder-response-container">
                <div className="results-title">Results</div>
                <Row className="finder-headings">
                    <ListColumn display="Date" />
                    <ListColumn display="Year" small={true} />
                    <ListColumn display="Color" small={true} />
                    <ListColumn display="Make" small={true} />
                    <ListColumn display="Model" />
                    <ListColumn display="VIN" />
                    <ListColumn display="LP#" />
                </Row>
                {vehicles.map((vehicle: any) => (
                    <Row className="found-vehicle" key={vehicle._id}>
                        <ListColumn
                            label="Date: "
                            display={dayjs(vehicle.createdAt).format(
                                "MM/DD/YYYY"
                            )}
                        />
                        <ListColumn
                            label="Year: "
                            display={vehicle.vehicleYear}
                            small={true}
                        />
                        <ListColumn
                            label="Color: "
                            display={vehicle.color}
                            small={true}
                        />
                        <ListColumn
                            label="Make: "
                            display={vehicle.make}
                            small={true}
                        />
                        <ListColumn label="Model: " display={vehicle.model} />
                        <ListColumn
                            label="VIN: "
                            display={vinMask(vehicle.vin)}
                        />
                        <ListColumn
                            label="LP#: "
                            display={vehicle.licensePlateNumber}
                        />
                        <Col span={2} xs={24} sm={24} md={2} lg={2} xl={2}>
                            <Button
                                type="primary"
                                block
                                onClick={() => setVehicleInfo(vehicle)}
                            >
                                Select
                            </Button>
                        </Col>
                    </Row>
                ))}
                {!isEmpty(vehicleInfo) && (
                    <Row className="vehicle-info">
                        <div>
                            This vehicle was towed by{" "}
                            <strong>{getTowCompanyName(vehicleInfo)}</strong>.
                            Their phone number is{" "}
                            <a href={`tel:${getTowCompanyPhone(vehicleInfo)}`}>
                                {formatPhone(getTowCompanyPhone(vehicleInfo))}
                            </a>
                            .
                            <br />
                            <br />
                            Call them to make an appointment to pick up your
                            impound, release the vehicle to your insurance
                            company, or for general questions about your vehicle
                            being towed.
                        </div>
                    </Row>
                )}
            </div>
        </Row>
    );
};

export default VehiclesList;

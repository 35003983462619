import gql from "graphql-tag";

export const searchQuery = gql`
    query TowPublicQuery($towInput: TowPublicQuery!) {
        TowPublicQuery(input: $towInput)
    }
`;

export const makesQuery = gql`
    query makesList($input: MakesQueryInput!) {
        ...Makes
    }

    fragment Makes on Query {
        Makes(input: $input) {
            Make_ID
            Make_Name
            __typename
        }
        __typename
    }
`;

export const modelsQuery = gql`
    query modelsList($input: ModelsQueryInput!) {
        ...Models
    }

    fragment Models on Query {
        Models(input: $input) {
            Model_ID
            Model_Name
            __typename
        }
        __typename
    }
`;

import { useState, useMemo, useRef } from "react";
import { Select, Spin, Form } from "antd";
import { debounce } from "lodash";

const DebounceSelect = (props: any) => {
    const [fetchingOptions, setFetchingOptions] = useState(false);
    const fetchRef = useRef(0);

    const makesDebounce = useMemo(() => {
        const loadOptions = (value: string) => {
            fetchRef.current += 1;
            setFetchingOptions(true);

            props.debounceFunction(value);
        };

        return debounce(loadOptions, 800);
    }, [props]);

    return (
        <Form.Item
            name={props.fieldName}
            rules={[
                {
                    required: true,
                    message: `Vehicle ${props.fieldName} is required`,
                },
            ]}
        >
            <Select
                disabled={props.fieldName === "model" ? props.dependent : false}
                showSearch
                labelInValue
                filterOption={false}
                onSelect={() => {
                    if (props.fieldName === "make" && props.setDependent) {
                        props.setDependent(false);
                    }
                }}
                onSearch={(e) => {
                    if (e) {
                        makesDebounce(e);
                    }
                }}
                notFoundContent={fetchingOptions ? <Spin size="small" /> : null}
                options={props.options}
            />
        </Form.Item>
    );
};

export default DebounceSelect;

import { Col } from "antd";

const FormColumn = (props: any) => {
    return (
        <Col
            xs={24}
            sm={12}
            md={12}
            lg={8}
            xl={4}
            style={{ position: "relative" }}
        >
            {props.field}
            <div className="label-container">{props.label}</div>
        </Col>
    );
};

export default FormColumn;

import React, { useReducer } from "react";
import StateContext from "./StateContext";
import { ACTIONS } from "./Constants";

const StateProvider = (props: any) => {
    const [state, dispatch] = useReducer(
        (prevState: any, action: any) => {
            switch (action.type) {
                case ACTIONS.SET_STATE:
                    return {
                        ...prevState,
                        state: action.state
                    };
                default:
                    throw new Error("Undefined action provided.");
            }
        },
        {
            state: '',
        }
    );

    return (
        <StateContext.Provider value={{ state, dispatch }}>
            {props.children}
        </StateContext.Provider>
    );
};

export default StateProvider;

import React from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";

import PublicLayout from "./core/layouts/public/PublicLayout";
// import AuthenticatedLayout from "./core/layouts/authenticated/AuthenticatedLayout";
// import SecureRoute from "./core/layouts/authenticated/SecureRoute";

import "./App.scss";
import StateProvider from "./shared/context/StateProvider";

function App() {
    return (
        <div>
            <StateProvider>
                <BrowserRouter>
                    <Switch>
                        <Route path="/" component={PublicLayout} />
                        {/* <SecureRoute>
                            <Route exact path="/" component={AuthenticatedLayout} />
                        </SecureRoute> */}
                        <Route path="/">
                            <Redirect to="/" />
                        </Route>
                    </Switch>
                </BrowserRouter>
            </StateProvider>
        </div>
    );
}

export default App;

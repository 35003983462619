import { Col, Row } from "antd";
import "./Informationals.scss";

interface IProps {
    bodyType: string;
}

const InformationalBodies = (props: IProps) => {
    const { bodyType } = props;

    if (bodyType === "crash") {
        return (
            <div className="information-body">
                <div className="information-container">
                    <Row>
                        <h2>If you have insurance...</h2>
                    </Row>
                    <Row style={{ paddingLeft: "1rem" }}>
                        <Col>
                            <p>
                                1. Call your insurance company and let them know
                                your vehicle has been involved in an accident
                            </p>
                            <p>
                                2. Tell them where your vehicle is being stored
                            </p>
                            <p>
                                3. Verify with your insurance company that you
                                will not be liable for the tow and storage fees
                            </p>
                        </Col>
                    </Row>
                </div>
                <div className="information-container">
                    <Row>
                        <h2>
                            If you have no insurance or have “liability only”
                            insurance...
                        </h2>
                    </Row>
                    <Row style={{ paddingLeft: "1rem" }}>
                        <Col>
                            <p>
                                1. Call the tow yard to make arrangements to
                                have your vehicle removed from the impound yard
                            </p>
                            <p>
                                If you do not have collision coverage, some
                                policies include towing coverage. Check your
                                insurance policy or ask your insurance agent to
                                verify your coverage.
                            </p>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
    if (bodyType === "police") {
        return (
            <div className="information-body">
                <div className="information-container">
                    <Row>
                        <h2>To release your vehicle from impound:</h2>
                    </Row>
                    <Row style={{ paddingLeft: "1rem" }}>
                        <Col>
                            <p>
                                1. Call the tow company to make an appointment
                                and verify any payment amount
                            </p>
                            <p>
                                2. Go to the DMV, pay any fees due and obtain a
                                state tax impound release form
                            </p>
                            <p>
                                3. Take the letter of impound release and proof
                                of identity to the impound yard
                            </p>
                            <p>4. Pay the towing and storage fees</p>
                            <p>
                                If you have any questions regarding your
                                impounded vehicle, please contact the impound
                                yard where your vehicle is being located. If you
                                have questions regarding the impound process,
                                please contact the Utah State Tax Commission at
                                801-297-7780.
                            </p>
                            <p>
                                If you fail to claim your vehicle within 30 days
                                of impound your vehicle may be sold at public
                                auction. Utah Code 41-1a-1103, Sale Motor
                                Vehicle Act.
                            </p>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
    if (bodyType === "private") {
        return (
            <div className="information-body">
                <div className="information-container">
                    <Row>
                        <h2>
                            If your vehicle was removed from private property
                        </h2>
                    </Row>
                    <Row style={{ paddingLeft: "1rem" }}>
                        <Col>
                            <p>
                                1. Call the tow company where your vehicle is
                                being stored
                            </p>
                            <p>2. Present proof of identity</p>
                            <p>3. Pay the towing and storage fees</p>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
    return <div></div>;
};

export default InformationalBodies;

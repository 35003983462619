import { useState } from "react";
import { Col, Modal, Row } from "antd";
import "./Informationals.scss";
import { informationBoxes } from "./informationalBoxes";
import InformationalBodies from "./InformationalBodies";
import { findIndex } from "lodash";

const Informationals = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalBody, setModalBody] = useState("");

    const toggleModal = (selectionType: string) => {
        setShowModal(!showModal);
        if (selectionType !== "") {
            setModalBody(selectionType);
        } else {
            setModalBody("");
        }
    };

    return (
        <div className="informationals">
            <Row gutter={80} justify="center">
                {informationBoxes.map((selection) => {
                    return (
                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={12}
                            xl={8}
                            key={selection.title}
                        >
                            <div
                                className="selection-box"
                                onClick={() => {
                                    toggleModal(selection.selectionType);
                                }}
                            >
                                <h3>{selection.title}</h3>
                                <div className="selection-icon-container">
                                    <img
                                        className="selection-icon"
                                        src={selection.icon}
                                        alt={selection.selectionType}
                                    />
                                </div>
                            </div>
                        </Col>
                    );
                })}
            </Row>
            <Modal
                title={
                    modalBody !== ""
                        ? informationBoxes[
                              findIndex(informationBoxes, {
                                  selectionType: modalBody,
                              })
                          ].title
                        : ""
                }
                visible={showModal}
                onCancel={() => toggleModal("")}
                footer={false}
                width={"75%"}
            >
                <InformationalBodies bodyType={modalBody} />
            </Modal>
        </div>
    );
};

export default Informationals;

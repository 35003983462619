import USAMap from "react-usa-map";
import styles from "./Map.module.scss";
import { useContext } from "react";
import StateContext from "../../../shared/context/StateContext";

interface IProps {
    setState: (state: string) => void;
}

const Map = (props: IProps) => {
    const { setState } = props;
    const { dispatch } = useContext(StateContext);

    const mapHandler = (event: any) => {
        const state = event.target.dataset.name;
        if (state !== null && state !== "AK" && state !== "HI") {
            setState(state);
            dispatch({ type: "SET_STATE", state });
        }
    };

    /* optional customization of filling per state and calling custom callbacks per state */
    const statesCustomConfig = () => {
        return {
            AK: {
                fill: "#ffffff",
                // clickHandler: (event: any) =>
                //     alert("Please, select another state."),
            },
            HI: {
                fill: "#ffffff",
                // clickHandler: (event: any) =>
                //     alert("Please, select another state."),
            },
        };
    };

    return (
            <div className="map-container">
                <h1>Select your state</h1>
                <USAMap
                    className="map"
                    width="100%"
                    title="Select your state"
                    customize={statesCustomConfig()}
                    onClick={mapHandler}
                />
            </div>
    );
};

export default Map;

import { Context, createContext } from "react";

export interface IStateContext {
    state: string;
}

const initialState: IStateContext = {
    state: ''
};

const StateContext: Context<{ state: IStateContext; dispatch: any }> =
    createContext({
        state: initialState,
        dispatch: () => {},
    });

export default StateContext;

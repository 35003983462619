import { makesQuery, modelsQuery, searchQuery } from "./search.gql";
import { TowProApolloClient } from "../towpro-apollo-client";

export const requestSearch = (towInput: any) => {
    return new Promise((resolve, reject) => {
        TowProApolloClient()
            .query({
                query: searchQuery,
                variables: {
                    towInput,
                },
            })
            .then((resp) => {
                if (resp.errors) {
                    reject(resp);
                    return;
                }
                resolve(resp.data.TowPublicQuery);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const formatMakes = (makes: any) => {
    let formattedValues: any = [];
    makes.map((make: any) => {
        const newMake = { label: make.Make_Name, value: make.Make_Name };
        return formattedValues.push(newMake);
    });
    return formattedValues;
};

export const makesSearch = (input: any) => {
    return new Promise((resolve, reject) => {
        TowProApolloClient()
            .query({
                query: makesQuery,
                variables: {
                    input,
                },
            })
            .then((resp) => {
                if (resp.errors) {
                    reject(resp);
                    return;
                }
                resolve(formatMakes(resp.data.Makes));
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const formatModels = (models: any) => {
    let formattedValues: any = [];
    models.map((model: any) => {
        const newModel = { label: model.Model_Name, value: model.Model_Name };
        return formattedValues.push(newModel);
    });
    return formattedValues;
};

export const modelsSearch = (input: any) => {
    return new Promise((resolve, reject) => {
        TowProApolloClient()
            .query({
                query: modelsQuery,
                variables: {
                    input,
                },
            })
            .then((resp) => {
                if (resp.errors) {
                    reject(resp);
                    return;
                }
                resolve(formatModels(resp.data.Models));
            })
            .catch((err) => {
                reject(err);
            });
    });
};
